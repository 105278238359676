export const themeLight = {
  background: '#FEFEFE',
  color: '#141414',
  colors: {
    mainBlue: '#46BAE8',
    lightBlue: '#40FBFF',
    mainRed: '#FF0000',
    mainBlack: '#141414',
    mainWhite: '#FFFFFF'
  },
  media: {
    tablet: '@media (min-width: 700px)',
    tabletXL: '@media (min-width: 1023px)',
    laptop: '@media (min-width: 1200px)',
    laptopXL: '@media (min-width: 1400px)',
    desktop: '@media (min-width: 1600px)',
    desktopXL: '@media (min-width: 2200px)',
  },
  font: {
    mainFont: `'Kanit', sans-serif`
  },
}

export const themeDark = {
  background: '#141414',
  color: '#FEFEFE',
  colors: {
    mainBlue: '#46BAE8',
    lightBlue: '#40FBFF',
    mainRed: '#FF0000',
    mainBlack: '#141414',
    mainWhite: '#FFFFFF'
  },
  media: {
    tablet: '@media (min-width: 700px)',
    tabletXL: '@media (min-width: 1023px)',
    laptop: '@media (min-width: 1200px)',
    laptopXL: '@media (min-width: 1400px)',
    desktop: '@media (min-width: 1600px)',
    desktopXL: '@media (min-width: 2200px)',
  },
  font: {
    mainFont: `'Kanit', sans-serif`
  },
}
