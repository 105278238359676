import styled from 'styled-components';
import { motion } from 'framer-motion'


export const NavWrapper = styled(motion.div)`
  height: 0px;
  width: 100%;
  position: absolute;
  top: 72px;
  right: 0;
  left: 0;
  z-index: 99;
`;

export const Logo = styled.div`
  width: 8rem;

  img {
    width: 100%;
  }
`

export const Dot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  /* background: ${({theme}) => theme.colors.mainBlue}; */
  border: 2px solid ${({theme}) => theme.colors.mainBlue};
  margin-left: 1rem;

    svg {
      width: 1.5rem;
    }
`;

export const Menu = styled.div`
button {
  transform-origin: center;
  border: none;
  padding: 1rem;
  background: transparent;
  outline: none;

  span {
    width: 2rem;
    height: .5rem;
    display: block;
    margin: .5rem 0;
    background: ${({theme}) => theme.colors.mainBlue};
  }
}
`


export const LeftSideMenu = styled.div`
  display: flex;
`