import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  width: auto;
  height: 100%;

  ${({theme}) => theme.media.tabletXL} {
    max-width: 960px;
  }
  ${({theme}) => theme.media.laptopXL} {
    max-width: 1152px;
  }
  ${({theme}) => theme.media.desktop} {
    max-width: 1245px;
  }

  ${props => props.fluid && css`
    padding: 0;
    /* margin: 0; */
    max-width: 100%;
  `}

  ${props => props.flexWrapper && css`
    display: flex;
  `}

  .arrow-image {
    position: absolute;
    top: 5%;
    left: -25%;
    width: 75%;
    z-index: -1;

    ${({theme}) => theme.media.laptop} {
      top: -5%;
      left: -30%;
      width: 60%;
    }
    ${({theme}) => theme.media.laptopXL} {
      top: -5%;
      left: -20%;
      width: 65%;
    }
    ${({theme}) => theme.media.desktop} {
      top: -5%;
      left: -30%;
      width: 65%;
    }
  }
`;


export const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${props => props.spaceBetween && css`
    justify-content: space-between;
  `}
  ${props => props.flexEnd && css`
    justify-content: flex-end;
  `}
  ${props => props.alignTop && css`
    align-items: flex-start;
  `}
  ${props => props.noHeight && css`
    height: 0;
  `}
  ${props => props.columnDirection && css`
    flex-direction: column;
  `}
`;


export const Cursor = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background: ${({theme}) => theme.colors.mainBlue};
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all .1s ease-in-out;
  transition-property: width, height, border;
  will-change: width, height, transform, border;
  pointer-events: none;
  z-index: 999;

  &.pointer {
    border: 3px solid ${({theme}) => theme.color} !important;
  }

  &.hovered {
    background: transparent !important;
    width: 46px;
    height: 46px;
    border: 3px solid ${({theme}) => theme.colors.mainBlue};
  }

  &.nav-open {
    background: ${({theme}) => theme.background};
  }
`;