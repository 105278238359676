import styled from 'styled-components';
import { motion } from 'framer-motion';


export const Nav = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: ${({theme}) => theme.colors.mainBlue};
  color: ${({theme}) => theme.colors.mainBlack};
  z-index: 100;
  overflow: hidden;
`


export const NavHeader = styled.div`
  position: relative;
  top: 7.5rem;

  h2 {
    color: ${({theme}) => theme.color};
  }
`


export const NavList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  ul {
    padding: 0;

    li {
      list-style: none;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 900;
      height: 3.5rem;
      line-height: 3.5rem;
      overflow: hidden;

      ${({theme}) => theme.media.tabletXL} {
        font-size: 3rem;
        height: 5rem;
        line-height: 5rem;
      }

      .link {
        position: relative;
        display: flex;
        align-items: center;
        color: ${({theme}) => theme.color};

        .arrow {
          height: 4.5rem;
          margin-right: .5rem;

          svg {
            width: 4.5rem;
            path {
              fill: ${({theme}) => theme.color};
            }
          }
        }
      }
    }
  }
`


export const NavFooter = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 2rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  ${({theme}) => theme.media.laptop} {
    width: 100%;
  }
`


export const Left = styled.div`
  display: flex;

  a {
    font-weight: 600;
    color: ${({theme}) => theme.color};
  }
`


export const Right = styled.div`

${({theme}) => theme.media.laptop} {
  margin-right: 5rem;
}
${({theme}) => theme.media.desktop} {
  margin-right: 6rem;
}

  a {
    color: ${({theme}) => theme.color};
    font-size: 1.3rem;
    margin: 0 .5rem;
  }
`


export const NavVideos = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30%;
  z-index: -1;
  height: 100%;
  width: 100%;
  background: ${({theme}) => theme.colors.mainBlack};

  .reveal {
    width: 100%;
    background: ${({theme}) => theme.colors.mainBlue};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .video {
    position: absolute;
    height: 100%;
    margin: 0;
    z-index: -1;
    background: ${({theme}) => theme.colors.mainBlack};

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`


export const CloseNav = styled.div`
  button {
    transform-origin: center;
    border: none;
    padding: 1.5rem;
    background: transparent;
    outline: none;

    span {
      width: 2rem;
      height: .5rem;
      display: block;
      background: ${({theme}) => theme.color};
      margin: .5rem;
    }
  }
`