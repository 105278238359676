import React, { useState, Fragment } from 'react'
import { Link } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

import { Container, Flex } from '../globalStyles.css'
import { Nav, NavHeader, NavList, NavFooter, CloseNav, NavVideos, Left, Right } from './navigation.css'

import cameraVideo from '../../assets/video/menu/Camera.mp4'
import wallVideo from '../../assets/video/menu/Video-Wall.mp4'
import referenceVideo from '../../assets/video/menu/Reference.mp4'
import mailVideo from '../../assets/video/menu/Mail.mp4'
import liveVideo from '../../assets/video/Camera2.mp4'


const navRoutes = [
  {id: 1, title: 'Strona główna', path: '/', video: cameraVideo},
  {id: 2, title: 'Projekty', path: '/projekty', video: wallVideo},
  {id: 3, title: 'Referencje', path: '/referencje', video: referenceVideo},
  {id: 4, title: 'Kontakt', path: '/kontakt', video: mailVideo},
  {id: 5, title: 'Live', path: 'https://live.blustreamtv.pl', video: liveVideo},
]

const Navigation = ({ toggleMenu, setToggleMenu, onCursor }) => {

  const [revealVideo, setRevealVideo] = useState({
    show: false,
    video: cameraVideo,
    key: '0',
  })

  return (
    <Fragment>
      <AnimatePresence>
        {toggleMenu && (
          <Nav
            initial={{x: '-100%'}}
            exit={{x: '-100%'}}
            animate={{x: toggleMenu ? 0 : '-100%'}}
            transition={{
              duration: 0.8,
              ease: [0.6, 0.05, -0.01, 0.9],
            }}
          >
          <Container>
            <NavHeader>
              <Flex spaceBetween noHeight>
                <h2>Start</h2>
                <CloseNav 
                  onClick={() => setToggleMenu(!toggleMenu)} 
                  onMouseEnter={() => onCursor('pointer')}
                  onMouseLeave={onCursor}
                >
                  <button
                    onMouseEnter={() => onCursor('pointer')}
                    onMouseLeave={onCursor}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </CloseNav>
              </Flex>
            </NavHeader>

            <NavList>
              <ul>
                {navRoutes.map(route => (
                  <motion.li
                    onMouseEnter={() => onCursor('pointer')}
                    onMouseLeave={onCursor} 
                    key={route.id}
                    onHoverStart={() => setRevealVideo({
                      show: true,
                      video: route.video,
                      key: route.id,
                      })
                    }
                    onHoverEnd={() => setRevealVideo({
                      show: false,
                      video: route.video,
                      key: route.id,
                    })}
                  >

                    {route.id === 5 ? (
                      <a href={route.path} target="_blank" rel="noreferrer">
                        <motion.div 
                        style={{color: '#FF0000'}}
                        initial={{x: -80}} 
                        whileHover={{x: -20, transition: {
                          duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9],
                        }}} 
                        className='link'
                      >
                        <span className='arrow'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 57" >
                            <path
                            d="M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z"
                            fill="#000"
                            fillRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        {route.title} <FontAwesomeIcon icon={faPlayCircle} style={{marginLeft: '10px', padding: '5px'}}/>
                      </motion.div>
                      </a>
                    ) : (
                      <Link to={route.path}>
                      <motion.div 
                        initial={{x: -80}} 
                        whileHover={{x: -20, transition: {
                          duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9],
                        }}} 
                        className='link'
                      >
                        <span className='arrow'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 57" >
                            <path
                            d="M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z"
                            fill="#000"
                            fillRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        {route.title}
                      </motion.div>
                    </Link>
                    )}

                    {/* <Link to={route.path}>
                      <motion.div 
                        initial={{x: -80}} 
                        whileHover={{x: -20, transition: {
                          duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9],
                        }}} 
                        className='link'
                      >
                        <span className='arrow'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 57" >
                            <path
                            d="M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z"
                            fill="#000"
                            fillRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        {route.title}
                      </motion.div>
                    </Link> */}
                  </motion.li>
                ))}
              </ul>
            </NavList>

            <NavFooter>
              <Left>
                <a 
                  onMouseEnter={() => onCursor('pointer')}
                  onMouseLeave={onCursor}
                  href="https://www.blusoft.pl" target="_blank" rel="noreferrer"
                >BluSoft <FontAwesomeIcon icon={faCopyright} /> 2020</a>
              </Left>
              <Right>
                <a
                  href="https://www.facebook.com/ePulpit24" target="_blank" rel="noreferrer"
                  onMouseEnter={() => onCursor('pointer')}
                  onMouseLeave={onCursor}
                >
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
                <a
                  href="https://www.linkedin.com/company/18892638/" target="_blank" rel="noreferrer"
                  onMouseEnter={() => onCursor('pointer')}
                  onMouseLeave={onCursor}
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCI_HLm7_fkf2N5JJv9uCSQA" target="_blank" rel="noreferrer"
                  onMouseEnter={() => onCursor('pointer')}
                  onMouseLeave={onCursor}
                >
                  <FontAwesomeIcon icon={faYoutubeSquare} />
                </a>
              </Right>
            </NavFooter>
            <NavVideos>
              <motion.div 
                className="reveal"
                animate={{width: revealVideo.show ? 0 : '100%'}}
              ></motion.div>
                <div className="video">
                  <AnimatePresence
                  initial={false}
                  exitBeforeEnter
                  >
                    <motion.video
                    key={revealVideo.key}
                    src={revealVideo.video}
                    initial={{opacity: 0}}
                    exit={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{
                      duration: 0.2,
                      ease: 'easeInOut',
                    }}
                    loop
                    autoPlay
                    muted
                    >
                    </motion.video>
                  </AnimatePresence>
                  {/* <video autoPlay loop muted>
                    <source src={revealVideo.video} type="video/mp4"></source>
                  </video> */}

                </div>
              </NavVideos>
            </Container>
          </Nav>
        )}
        
      </AnimatePresence>
    </Fragment>
  )
}

export default Navigation
