import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Container, Flex } from '../globalStyles.css'
import { FooterSection, About, Service } from './footer.css'


const Footer = ({ onCursor }) => {
  return (
    <FooterSection>
      <Container>
        <Flex spaceBetween alignTop className="footer-flex-wrapper">
          <About>
            <h3>Oferujemy pełną obsługa produkcji wideo, twórcze tworzenie i obsługę postprodukcyjną</h3>
            <Link to="/kontakt">
              <button
                onMouseLeave={onCursor}
                onMouseEnter={() => onCursor('hovered')}
              >
                <span>Napisz do nas</span><FontAwesomeIcon icon={faArrowRight} />
              </button>
            </Link>
            <p>Streaming jest coraz częściej sposobem na promocję atrakcji turystycznych, miast, miejsc atrakcyjnych krajobrazowo. Zapraszamy organizatorów imprez zainteresowanych usługą do współpracy.</p>
          </About>
          <Service>
            <h3>Nasze produkty</h3>
            <ul>
              <li>
                <a
                  onMouseLeave={onCursor}
                  onMouseEnter={() => onCursor('hovered')}
                  href="https://www.bluserwer.pl" target="_blank" rel="noopener noreferrer">- BluSerwer
                </a>
              </li>
              <li>
                <a 
                  onMouseLeave={onCursor}
                  onMouseEnter={() => onCursor('hovered')}
                  href="https://www.e-pulpit24.pl" target="_blank" rel="noopener noreferrer">- e-Pulpit24
                </a>
              </li>
              <li>
                <a 
                  onMouseLeave={onCursor}
                  onMouseEnter={() => onCursor('hovered')}
                  href="https://komputerwchmurze.pl" target="_blank" rel="noopener noreferrer">- Komputer w chmurze
                </a>
              </li>
              <li>
                <a
                  onMouseLeave={onCursor}
                  onMouseEnter={() => onCursor('hovered')}
                   href="https://www.podpis-elektroniczny.bydgoszcz.pl" target="_blank" rel="noopener noreferrer">- Podpis elektroniczny
                 </a>
              </li>
              <li>
                <a 
                  onMouseLeave={onCursor}
                  onMouseEnter={() => onCursor('hovered')}
                  href="https://www.bluoffice.pl" target="_blank" rel="noopener noreferrer">- Blu-Office
                </a>
              </li>
            </ul>
          </Service>
        </Flex>
      </Container>
    </FooterSection>
  )
}

export default Footer
