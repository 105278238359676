import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

import { NavWrapper, Logo, Menu, Dot, LeftSideMenu } from './mainNav.css';
import { Container, Flex } from '../globalStyles.css';

import { useGlobalStateContext, useGlobalDispatchContext } from '../../context/globalContext'

const MainNav = ({ onCursor, toggleMenu, setToggleMenu  }) => {
  const data = useStaticQuery(graphql`
    query {
      logoImg: file(relativePath: { eq: "BluStreamTV_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const dispatch = useGlobalDispatchContext()
  const { currentTheme } = useGlobalStateContext()

  const toggleTheme = () => {
    if(currentTheme === 'dark') {
      dispatch({type: 'TOGGLE_THEME', theme: 'light'})
    } else {
      dispatch({type: 'TOGGLE_THEME', theme: 'dark'})
    }
  }


  useEffect(() => {
    window.localStorage.setItem('theme', currentTheme)
  }, [currentTheme])

  return (
  <NavWrapper
    animate={{y: 0, opacity: 1}}
    initial={{y: -72, opacity: 0}}
    transition={{duration: 1, ease: [0.6, 0.05, -0.01, 0.9]}}
  >
    <Container fluid>
      <Flex spaceBetween noHeight>
        <LeftSideMenu>
        <Logo
          onMouseEnter={() => onCursor('pointer')}
          onMouseLeave={onCursor}
        >
          <Link to='/'>
            <Img fluid={data.logoImg.childImageSharp.fluid} alt="logo e-Pulpit24"/>
          </Link>
          
        </Logo>
        <Dot 
          onClick={toggleTheme}
          onMouseEnter={() => onCursor('pointer')}
          onMouseLeave={onCursor}
        >
          {currentTheme === 'dark' ? <FontAwesomeIcon icon={faSun}/> : <FontAwesomeIcon icon={faMoon} /> }
        </Dot>
        </LeftSideMenu>
        <Menu onClick={() => setToggleMenu(!toggleMenu)}>
          <button
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          >
            <span></span>
            <span></span>
          </button>
        </Menu>

      </Flex>
    </Container>
  </NavWrapper>
  )
}


export default MainNav