
import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import { themeLight, themeDark } from '../utils/theme'

import MainNav from './mainNav/mainNav'
import Cursor from '../components/customCursor/customCursor'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'


const GlobalStyle = createGlobalStyle`
*, *::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  cursor: none;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: ${props => props.theme.color};
  background: ${({theme}) => theme.background};
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: ${({theme}) => theme.colors.mainWhite};
}

body::-webkit-scrollbar-thumb {
  background-color: ${({theme}) => theme.colors.mainRed};
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;

const MainWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;


const Layout = ({ children }) => {

  const { currentTheme, cursorStyles } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
  }

  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <ThemeProvider theme={currentTheme === 'dark' ? themeDark : themeLight}>
      <Fragment>
        <GlobalStyle/>
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;900&display=swap" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap" rel="stylesheet"/>
          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        </Helmet>

        <Cursor toggleMenu={toggleMenu}/>
        <MainNav 
          onCursor={onCursor}
          toggleMenu={toggleMenu}
          setToggleMenu={setToggleMenu}
        />
        <Navigation 
          toggleMenu={toggleMenu}
          setToggleMenu={setToggleMenu}
          onCursor={onCursor}
        />

        <MainWrapper>{children}</MainWrapper>
        <Footer onCursor={onCursor}/>

      </Fragment>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
