import styled from 'styled-components';


export const FooterSection = styled.footer`
  position: relative;
  padding-bottom: 4rem;

  &::before {
    position: absolute;
    content: '© BluSerwer.pl 2020';
    left: 0;
    bottom: 0;
    width: 80%;
    height: 1.5rem;
    font-size: 0.8rem;
    line-height: 1.5rem;
    text-align: center;
    color: ${({theme}) => theme.color};
    background: ${({theme}) => theme.colors.mainRed};

    ${({theme}) => theme.media.laptop} {
      width: 50%;
    }
  }

  .footer-flex-wrapper {
    flex-direction: column;

    ${({theme}) => theme.media.laptop} {
      flex-direction: row;
    }
  }
`


export const About = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;

    ${({theme}) => theme.media.laptopXL} {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  button {
    display: flex;
    justify-content: space-between;
    color: ${({theme}) => theme.colors.mainWhite};
    background: ${({theme}) => theme.colors.mainRed};
    padding: .5rem 1.3rem;
    font-size: 1.3rem;
    text-align: left;
    line-height: 1;
    font-weight: 400;
    border: none;

    span {
      margin-right: 1.5rem;
      display: block;
    }
  }

  p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.4;

    ${({theme}) => theme.media.laptopXL} {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  ${({theme}) => theme.media.laptop} {
    width: 55%;
    margin-bottom: 0;
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 50%;
  }
`


export const Service = styled.div`


  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;

    ${({theme}) => theme.media.laptopXL} {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  ul {
    list-style: none;

    li {
      line-height: 1.7;

      a {
        font-size: 0.9rem;
        font-weight: 600;
        color: ${({theme}) => theme.colors.mainBlue};
      }
    }
  }
`