import React, { Fragment, useState, useEffect } from "react"

import { Cursor } from '../globalStyles.css'

// Context
import { useGlobalStateContext } from '../../context/globalContext'


const CustomCursor = ({ toggleMenu }) => {
  const { cursorType } = useGlobalStateContext()

  const [mousePosition, setMousePosition] = useState({
    x: 100,
    y: 250,
  })

  const onMouseMove = (e) => {
    const { pageX: x, pageY: y } = e;
    setMousePosition({ x, y })
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  return (
    <Fragment>
      <Cursor 
        style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
        className={`${!!cursorType ? 'hovered' : ''} ${cursorType} ${toggleMenu ? 'nav-open' : ''}`}
      />
    </Fragment>
  )
}

export default CustomCursor